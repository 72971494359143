import { theme } from '@hatchddigital/livestock-pricing-shared-utils';
import { lighten } from 'polished';

export const elementTheme = {
  global: {
    color: theme.colors.darkGrey,
    backgroundColor: theme.colors.background,
    menuText: lighten(0.4, theme.colors.blue),
  },
  button: {
    disabledHoverColor: theme.colors.white,
    disabledHoverBackgroundColor: theme.colors.darkGrey,
    primary: {
      backgroundColor: theme.colors.blue,
      color: theme.colors.white,
      focus: {
        borderColor: theme.colors.blue,
      },
    },
    secondary: {
      color: theme.colors.darkGrey,
      backgroundColor: theme.colors.neutral,
      borderColor: theme.colors.black,
    },
    tertiary: {
      color: theme.colors.white,
      backgroundColor: theme.colors.pink,
      borderColor: theme.colors.black,
    },
    tertiaryOutlined: {
      color: theme.colors.pink,
      backgroundColor: 'transparent',
      innerBorderColor: theme.colors.pink,
      focus: {
        borderColor: theme.colors.pink,
        innerBorderColor: theme.colors.pink,
      },
    },
    outline: {
      color: theme.colors.blue,
      backgroundColor: 'transparent',
      innerBorderColor: theme.colors.blue,
      focus: {
        borderColor: theme.colors.blue,
        innerBorderColor: theme.colors.blue,
      },
    },
    outlineInverted: {
      color: theme.colors.white,
      innerBorderColor: theme.colors.white,
      focus: {
        innerBorderColor: theme.colors.white,
      },
    },
  },
  header: {
    homeLinkBorderColor: 'transparent',
    businessNameLogoColor: theme.colors.darkGrey,
    businessNameLogoBackgroundColor: theme.colors.background,
    backgroundColor: theme.colors.white,
    color: theme.colors.darkGrey,
  },
  navbar: {
    backgroundColor: theme.colors.darkBlue,
    hoverColor: theme.colors.blue,
    borderRightColor: 'transparent',
  },
  card: {
    backgroundColor: theme.colors.white,
  },
  checkbox: {
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.darkGrey,
    checkedBackgroundColor: theme.colors.darkGrey,
    disabled: {
      backgroundColor: theme.colors.neutral,
      borderColor: theme.colors.neutral,
    },
  },
  checkboxCard: {
    backgroundColor: theme.colors.background,
  },
  combobox: {
    backgroundColor: theme.colors.white,
    resultHoverColor: theme.colors.white,
    resultHoverBackgroundColor: theme.colors.darkGrey,
    inputBackgroundColor: theme.colors.white,
    selectedInputBackgroundColor: theme.colors.white,
    selectedItemBackgroundColor: theme.colors.neutral,
  },
  dialog: {
    backgroundColor: theme.colors.white,
    headerBorderBottomColor: theme.colors.neutral,
    closeButtonBorderColor: theme.colors.black,
  },
  fileInput: {
    backgroundColor: theme.colors.neutral,
    hoverColor: theme.colors.white,
    hoverBackgroundColor: theme.colors.darkGrey,
    hoverIconColor: theme.colors.white,
  },
  icon: {
    defaultColor: theme.colors.black,
  },
  input: {
    stateIconBackgroundColor: theme.colors.white,
  },
  curvedInput: {
    borderRadius: '50px',
  },
  editAccordion: {
    backgroundColor: theme.colors.white,
    borderBottomColor: theme.colors.neutral,
    borderLeftColor: theme.colors.neutral,
  },
  interestedSellerCard: {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.neutral,
  },
  priceGridEditNav: {
    backgroundColor: theme.colors.white,
    hoverItemColor: theme.colors.darkGrey,
    hoverItemBackgroundColor: theme.colors.neutralLight,
    clickedItemColor: theme.colors.white,
    clickedItemBackgroundColor: theme.colors.darkGrey,
  },
  pricePreviewCard: {
    backgroundColor: theme.colors.white,
    slotBorderColor: theme.colors.neutral,
    footerBackgroundColor: theme.colors.white,
  },
  priceTable: {
    firstColumnBorderColor: theme.colors.neutral,
    columnBorderColor: theme.colors.neutral,
    headerBackgroundColor: theme.colors.background,
    itemBackgroundColor: theme.colors.white,
    itemBorderColor: theme.colors.neutral,
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.neutral,
  },
  contentSection: {
    borderTopColor: theme.colors.neutral,
    borderBottomColor: theme.colors.neutral,
  },
  listCard: {
    clickedBackgroundColor: theme.colors.background,
    borderRadius: '20px',
  },
  moreMenu: {
    mobileBackgroundColor: theme.colors.black,
    contentBackgroundColor: theme.colors.white,
    itemHoverBackgroundColor: theme.colors.darkGrey,
    itemIconHoverColor: theme.colors.white,
    listBorderRadius: '20px',
  },
  radio: {
    radioButtonColor: theme.colors.darkGrey,
  },
  radioBox: {
    backgroundColor: theme.colors.lightBlue,
    borderColor: theme.colors.lightBlue,
    textColor: theme.colors.blue,
    checkedBorderColor: theme.colors.blue,
  },
  roundedTag: {
    backgroundColor: theme.colors.neutral,
  },
  table: {
    tableBorderColor: theme.colors.neutral,
    headerBackgroundColor: theme.colors.neutralLight,
    cellHeaderBorderColor: theme.colors.neutral,
    cellBorderColor: theme.colors.neutral,
    rowBackgroundColor: theme.colors.white,
  },
  tabs: {
    selectedTabUnderlineColor: theme.colors.blue,
    borderBottomColor: theme.colors.neutral,
    borderRightColor: theme.colors.background,
  },
  toggle: {
    backgroundColor: theme.colors.neutral,
    toggleButtonColor: theme.colors.white,
    clickedBackgroundColor: theme.colors.aqua,
    activeBackgroundColor: theme.colors.aqua,
  },
  tooltip: {
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
  },
  userNav: {
    color: theme.colors.black,
    backgroundColor: theme.colors.neutral,
    listBackgroundColor: theme.colors.white,
    itemColor: theme.colors.black,
    itemHoverColor: lighten(0.4, theme.colors.blue),
    itemHoverBackgroundColor: theme.colors.darkBlue,
    userNameLogoColor: theme.colors.white,
  },
  selectedSpecification: {
    borderBottomColor: theme.colors.neutral,
  },
  pricePane: {
    typeTagBorderColor: theme.colors.neutral,
    actionBarBackgroundColor: theme.colors.white,
  },
  listPane: {
    headerBackgroundColor: theme.colors.white,
    headerBorderBottomColor: theme.colors.neutral,
    columnBackgroundColor: theme.colors.neutralLight,
    contentBackgroundColor: theme.colors.white,
  },
  statusTag: {
    borderRadius: '40px',
  },
  select: {
    listBorderRadius: '8px',
  },
  privateGroupContactCount: {
    backgroundColor: theme.colors.lightBlue,
    color: theme.colors.darkBlue,
  },
  contactCard: {
    profilePictureBackground: theme.colors.pink,
  },
};
